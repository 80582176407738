import { SectionItemTitle } from '../message-additional-options.styled';
import {
  Container, Description, Input, InputsContainer, TitleContainer,
} from './text-input-item.styled';
import { TextInputItemProps } from './text-input-item';
import { TextInputItemDirection } from './text-input-item.enums';

const TextInputItem = ({
  title,
  value,
  description,
  placeholder,
  changeHandler,
  direction = TextInputItemDirection.ROW,
  fullWidth = false,
  children,
}: TextInputItemProps): JSX.Element => {
  return (
    <Container direction={ direction }>
      <TitleContainer>
        <SectionItemTitle>{ title }</SectionItemTitle>
        <Description>{ description }</Description>
      </TitleContainer>
      <InputsContainer>
        <Input
          placeholder={ placeholder }
          onChange={ changeHandler }
          value={ value }
          fullWidth={ fullWidth }
        />
        { children }
      </InputsContainer>
    </Container>
  );
};

export default TextInputItem;
