import { useEffect } from 'react';
import { useParams } from 'react-router';
import { FormikErrors, useFormik, FormikTouched } from 'formik';
import { useDispatch } from 'react-redux';
import {
  CreateFollowupsConfigDto,
  CreatePositionSequencePreferencesRequest,
  CreateSenderDto,
  CreatScheduleConfigDto,
} from '../../../../store/rtk-query/apis/sequence/dto/request/create-position-sequence-preferences.request';
import { SequenceScheduleModeEnum } from '../../../../store/rtk-query/apis/sequence/enums/sequence-schedule-days-mode.enum';
import { SequenceScheduleDaysModeEnum } from '../../../../store/rtk-query/apis/sequence/enums/sequence-schedule-mode.enum';
import { SequencePreferencesDto } from '../../../../store/rtk-query/apis/sequence/dto/sequence-preferences.dto';
import { SequenceFollowupsModeEnum } from '../../../../store/rtk-query/apis/sequence/enums/sequence-followups-mode.enum';
import { SequenceModeEnum } from '../../../../store/rtk-query/apis/sequence/enums/sequence-mode.enum';
import {
  CreateOrUpdatePositionSequencePreferencesQueryArguments,
} from '../../../../store/rtk-query/apis/sequence/dto/query-arguments/create-or-update-position-sequence-preferences.query-arguments';
import { setSequenceMode } from '../../../../store/slices/outreach-state/outreach-state.toolkit-slice';
import { SequenceMessageTypeEnum } from '../../../../store/rtk-query/apis/sequence/enums/sequence-message-type.enum';
import { OutreachSequencePreferencesFormikKeys } from './campaign.enums';

export type OutreachSequencePreferencesFormikValues = {
  [OutreachSequencePreferencesFormikKeys.Mode]: SequenceModeEnum,
  [OutreachSequencePreferencesFormikKeys.PrimarySenderId]: string | undefined,
  [OutreachSequencePreferencesFormikKeys.ScheduleMode]: SequenceScheduleModeEnum,
  [OutreachSequencePreferencesFormikKeys.ScheduleDaysType]: SequenceScheduleDaysModeEnum | undefined,
  [OutreachSequencePreferencesFormikKeys.ScheduleTime]: string | undefined,
  [OutreachSequencePreferencesFormikKeys.FollowupsMode]: SequenceFollowupsModeEnum,
  [OutreachSequencePreferencesFormikKeys.FollowupsNumber]: number | undefined,
  [OutreachSequencePreferencesFormikKeys.AutoArchiveConversationsWithoutEmail]: boolean,
  [OutreachSequencePreferencesFormikKeys.FasterFollowups]: boolean,
  [OutreachSequencePreferencesFormikKeys.MessageType]: SequenceMessageTypeEnum,
};

export type OutreachContentPreferencesFormik = OutreachSequencePreferencesFormikValues & {
  setValueByKey: (key: OutreachSequencePreferencesFormikKeys, value: unknown) => void,
  save: () => Promise<void>;
  dirty: boolean,
  isValid: boolean,
  resetForm: () => void,
  errors: FormikErrors<OutreachSequencePreferencesFormikValues>,
  touched: FormikTouched<OutreachSequencePreferencesFormikValues>,
};

const generateInitialValue = (preferences?: SequencePreferencesDto, sequenceMode?: SequenceModeEnum) => {
  const primarySender = preferences?.senders?.find((sender) => sender.isPrimarySender);
  console.log('preferences', preferences);

  return {
    [OutreachSequencePreferencesFormikKeys.Mode]: sequenceMode || preferences?.mode || SequenceModeEnum.Manual,
    [OutreachSequencePreferencesFormikKeys.PrimarySenderId]: primarySender?.user?.id || undefined,
    [OutreachSequencePreferencesFormikKeys.ScheduleMode]: preferences?.schedule?.mode || SequenceScheduleModeEnum.Auto,
    [OutreachSequencePreferencesFormikKeys.ScheduleDaysType]: preferences?.schedule?.days || undefined,
    [OutreachSequencePreferencesFormikKeys.ScheduleTime]: preferences?.schedule?.time ? preferences.schedule.time : undefined,
    [OutreachSequencePreferencesFormikKeys.FollowupsMode]: preferences?.followups?.mode || SequenceFollowupsModeEnum.Auto,
    [OutreachSequencePreferencesFormikKeys.FollowupsNumber]: preferences?.followups?.numOfFollowups || undefined,
    [OutreachSequencePreferencesFormikKeys.AutoArchiveConversationsWithoutEmail]: preferences?.autoArchiveConversationsWithoutEmail || false,
    [OutreachSequencePreferencesFormikKeys.FasterFollowups]: preferences?.fasterFollowups || false,
    [OutreachSequencePreferencesFormikKeys.MessageType]: preferences?.messageType || SequenceMessageTypeEnum.Email,
  };
};

const getSenders = (values: Partial<OutreachSequencePreferencesFormikValues>): CreateSenderDto[] | undefined => {
  const senderDto: CreateSenderDto = {} as CreateSenderDto;
  const primarySenderId = values[OutreachSequencePreferencesFormikKeys.PrimarySenderId];

  if (primarySenderId) {
    senderDto.userId = primarySenderId;
    senderDto.isPrimarySender = true;

    return [senderDto];
  }

  return undefined;
};

const getScheduleConfig = (values: Partial<OutreachSequencePreferencesFormikValues>): CreatScheduleConfigDto | undefined => {
  const scheduleConfigDto: CreatScheduleConfigDto = {} as CreatScheduleConfigDto;
  const isCustomMode = values[OutreachSequencePreferencesFormikKeys.ScheduleMode] === SequenceScheduleModeEnum.Custom;

  if (values[OutreachSequencePreferencesFormikKeys.ScheduleMode]) {
    scheduleConfigDto.mode = values[OutreachSequencePreferencesFormikKeys.ScheduleMode];
  }

  if (isCustomMode) {
    if (values[OutreachSequencePreferencesFormikKeys.ScheduleDaysType]) {
      scheduleConfigDto.days = values[OutreachSequencePreferencesFormikKeys.ScheduleDaysType];
    }

    if (values[OutreachSequencePreferencesFormikKeys.ScheduleTime]) {
      scheduleConfigDto.time = values[OutreachSequencePreferencesFormikKeys.ScheduleTime];
    }
  }

  if (Object.keys(scheduleConfigDto).length) {
    return scheduleConfigDto;
  }

  return undefined;
};

const getFollowupsConfig = (values: Partial<OutreachSequencePreferencesFormikValues>): CreateFollowupsConfigDto | undefined => {
  const followupsConfigDto: CreateFollowupsConfigDto = {} as CreateFollowupsConfigDto;
  const isCustomMode = values[OutreachSequencePreferencesFormikKeys.FollowupsMode] === SequenceFollowupsModeEnum.Custom;

  if (values[OutreachSequencePreferencesFormikKeys.FollowupsMode]) {
    followupsConfigDto.mode = values[OutreachSequencePreferencesFormikKeys.FollowupsMode];
  }

  if (isCustomMode) {
    if (values[OutreachSequencePreferencesFormikKeys.FollowupsNumber]) {
      followupsConfigDto.numOfFollowups = values[OutreachSequencePreferencesFormikKeys.FollowupsNumber];
    }
  }

  if (Object.keys(followupsConfigDto).length) {
    return followupsConfigDto;
  }

  return undefined;
};

const getRequestData = (changedValues: Partial<OutreachSequencePreferencesFormikValues>, sequencePreferencesDto?: SequencePreferencesDto) => {
  const data: CreatePositionSequencePreferencesRequest = {
    sequencePreferences: {
      mode: changedValues.mode || undefined,
      autoArchiveConversationsWithoutEmail: changedValues.autoArchiveConversationsWithoutEmail,
    },
  };

  if (changedValues.mode === SequenceModeEnum.Auto) {
    data.sequencePreferences = {
      ...data.sequencePreferences,
      senders: getSenders(changedValues),
      schedule: getScheduleConfig(changedValues),
      followups: getFollowupsConfig(changedValues),
      messageType: changedValues.messageType,
      fasterFollowups: changedValues.fasterFollowups,
    };
  }

  if (changedValues.mode === SequenceModeEnum.Custom) {
    data.sequencePreferences = {
      ...data.sequencePreferences,
      senders: getSenders(changedValues),
      customSteps: sequencePreferencesDto?.customSteps,
    };
  }

  return data;
};

export const useOutreachSequencePreferencesFormik = (
  submitHandler: (positionSequencePreferences: CreateOrUpdatePositionSequencePreferencesQueryArguments) => void,
  sequencePreferencesDto?: SequencePreferencesDto,
  sequenceMode?: SequenceModeEnum,
): OutreachContentPreferencesFormik => {
  const dispatch = useDispatch();
  const { positionId } = useParams<MatchUrlParams>();
  const initialValues = generateInitialValue(sequencePreferencesDto);
  const formik = useFormik<OutreachSequencePreferencesFormikValues>({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values: OutreachSequencePreferencesFormikValues) => {
      const requestArguments = {
        positionId,
        data: getRequestData(values, sequencePreferencesDto),
      };

      return submitHandler(requestArguments);
    },
    validate: (values: OutreachSequencePreferencesFormikValues) => {
      const errors: OutreachSequencePreferencesFormikValues = {} as OutreachSequencePreferencesFormikValues;
      const shouldCheckPrimarySander = values[OutreachSequencePreferencesFormikKeys.Mode] === SequenceModeEnum.Auto ||
        values[OutreachSequencePreferencesFormikKeys.Mode] === SequenceModeEnum.Custom;
      if (shouldCheckPrimarySander && !values[OutreachSequencePreferencesFormikKeys.PrimarySenderId]) {
        errors[OutreachSequencePreferencesFormikKeys.PrimarySenderId] = 'Primary sender id cannot be null';
      }

      return errors;
    },
  });

  useEffect(() => {
    if (sequenceMode) {
      formik.setFieldValue(OutreachSequencePreferencesFormikKeys.Mode, sequenceMode);
      dispatch(setSequenceMode());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sequenceMode]);

  return {
    [OutreachSequencePreferencesFormikKeys.Mode]: formik.values[OutreachSequencePreferencesFormikKeys.Mode],
    [OutreachSequencePreferencesFormikKeys.PrimarySenderId]: formik.values[OutreachSequencePreferencesFormikKeys.PrimarySenderId],
    [OutreachSequencePreferencesFormikKeys.ScheduleMode]: formik.values[OutreachSequencePreferencesFormikKeys.ScheduleMode],
    [OutreachSequencePreferencesFormikKeys.ScheduleDaysType]: formik.values[OutreachSequencePreferencesFormikKeys.ScheduleDaysType],
    [OutreachSequencePreferencesFormikKeys.ScheduleTime]: formik.values[OutreachSequencePreferencesFormikKeys.ScheduleTime],
    [OutreachSequencePreferencesFormikKeys.FollowupsMode]: formik.values[OutreachSequencePreferencesFormikKeys.FollowupsMode],
    [OutreachSequencePreferencesFormikKeys.FollowupsNumber]: formik.values[OutreachSequencePreferencesFormikKeys.FollowupsNumber],
    [OutreachSequencePreferencesFormikKeys.AutoArchiveConversationsWithoutEmail]:
      formik.values[OutreachSequencePreferencesFormikKeys.AutoArchiveConversationsWithoutEmail],
    [OutreachSequencePreferencesFormikKeys.FasterFollowups]: formik.values[OutreachSequencePreferencesFormikKeys.FasterFollowups],
    [OutreachSequencePreferencesFormikKeys.MessageType]: formik.values[OutreachSequencePreferencesFormikKeys.MessageType],
    setValueByKey: (key, value) => {
      formik.setFieldTouched(key);
      formik.setFieldValue(key, value);
    },
    save: formik.submitForm,
    dirty: formik.dirty,
    errors: formik.errors,
    isValid: formik.isValid,
    resetForm: formik.resetForm,
    touched: formik.touched,
  };
};
