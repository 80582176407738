import {
  Container, SectionItemTitle, Description, TitleContainer,
} from './base-input-item.styled';

const BaseInputItem = ({ title, description, children }: BaseInputItemProps): JSX.Element => {
  return (
    <Container>
      <TitleContainer>
        <SectionItemTitle>{ title }</SectionItemTitle>
        <Description>{ description }</Description>
      </TitleContainer>
      { children }
    </Container>
  );
};

export default BaseInputItem;
