/* eslint-disable react/jsx-props-no-spreading */
import { useState, useRef } from 'react';
import { usePopper } from 'react-popper';
import Portal from '../Common/Portal/portal.commponent';
import { TooltipContainer, TooltipTip } from './tooltip.styled';
import { TooltipProps } from './tooltip';

// TODO: BUG has issues on multiple tooltips.
const Tooltip = ({
  content,
  children,
  placement = 'top',
  showTooltipDelay = 0,
  hideTooltipDelay = 500,
  customTooltipStyle,
  ...otherProps
}: TooltipProps): JSX.Element => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const [isTooltipHover, setIsTooltipHover] = useState(false);

  const showTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const hideTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: [{ name: 'offset', options: { offset: [0, 6] } }],
  });

  const handleMouseEnter = () => {
    if (hideTimeoutRef.current) {
      clearTimeout(hideTimeoutRef.current);
      hideTimeoutRef.current = null;
    }

    showTimeoutRef.current = setTimeout(() => {
      setIsTooltipVisible(true);
    }, showTooltipDelay);
  };

  const handleMouseLeave = () => {
    if (showTimeoutRef.current) {
      clearTimeout(showTimeoutRef.current);
      showTimeoutRef.current = null;
    }

    hideTimeoutRef.current = setTimeout(() => {
      setIsTooltipVisible(false);
    }, hideTooltipDelay);
  };

  const handleTooltipMouseEnter = () => {
    // Prevent the tooltip from hiding when hovering over it
    setIsTooltipHover(true);
    if (hideTimeoutRef.current) {
      clearTimeout(hideTimeoutRef.current);
      hideTimeoutRef.current = null;
    }
  };

  const handleTooltipMouseLeave = () => {
    setIsTooltipHover(false);
    handleMouseLeave();
  };

  return (
    <>
      <TooltipContainer
        onMouseEnter={ handleMouseEnter }
        onMouseLeave={ handleMouseLeave }
        ref={ setReferenceElement }
        { ...otherProps }
      >
        {children}
        {(isTooltipVisible || isTooltipHover) && (
          <Portal containerId="tooltip-container" isGlobalPortal>
            <TooltipTip
              onMouseEnter={ handleTooltipMouseEnter }
              onMouseLeave={ handleTooltipMouseLeave }
              ref={ setPopperElement }
              style={ {
                ...customTooltipStyle,
                ...styles.popper,
              } }
              { ...attributes.popper }
            >
              {content}
            </TooltipTip>
          </Portal>
        )}
      </TooltipContainer>
    </>
  );
};

export default Tooltip;
