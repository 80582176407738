import styled from 'styled-components';
import { TextInputCommonStyle } from '../message-additional-options.styled';
import { THEME } from '../../../../../themes';
import { CaptionFont } from '../../../../../themes/fonts';
import { TextInputItemDirection } from './text-input-item.enums';

export const Container = styled.div<{ direction: TextInputItemDirection }>`
  display: flex;
  justify-content: space-between;
  align-items: ${({ direction }) => direction === TextInputItemDirection.ROW ? 'center' : undefined};
  flex-direction: ${({ direction }) => direction === TextInputItemDirection.ROW ? 'row' : 'column'};
  gap: ${THEME.SPACING.TINO};
`;

export const Input = styled.input<{ fullWidth: boolean }>`
  height: 48px;
  width: ${({ fullWidth }) => fullWidth ? '100%' : '50%'};
  ${TextInputCommonStyle}
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.TINY};
`;

export const Description = styled.div`
  ${CaptionFont};
  color: ${THEME.COLORS.CONTENT_A};
  opacity: ${THEME.OPACITY.MED};
`;

export const InputsContainer = styled.div`
  display: flex;
  gap: ${THEME.SPACING.TINY};
`;
