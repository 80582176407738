import {
  MouseEvent, useEffect, useMemo, useRef, useState,
} from 'react';
import Draft from 'draft-js';
import { differenceInCalendarDays } from 'date-fns';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { THEME } from '../../../../../themes';
import { outreachQuery } from '../../../../../store/rtk-query/apis/outreach/outreach.toolkit-api';
import { useCancelConversationSequenceMutation } from '../../../../../store/rtk-query/apis/sequence/hooks/cancel-conversation-sequence.mutation-hook';
import { useCreateConversationSequence } from '../../../../../store/rtk-query/apis/sequence/hooks/create-conversation-sequence.mutation-hook';
import { SequenceCreationStatusEnum } from '../../../../../store/rtk-query/apis/outreach/enums/sequence-creation-status.enum';
import { contentToHtml } from '../../../../../components/Common/perfect-text-editor/perfect-text-editor.utils';
import { useUpdateScheduledMessageMutation } from '../../../../../store/rtk-query/apis/sequence/hooks/update-scheduled-message.mutation-hook';
import { KEYBOARD_KEYS } from '../../../../../consts/keyboard_keys';
import { LinkedinTaskStatusEnum } from '../../../../../store/rtk-query/apis/outreach/enums/linkedin-task-status.enum';
import { sequenceQuery } from '../../../../../store/rtk-query/apis/sequence/sequence.toolkit-api';
import { useMyUserSelector } from '../../../../../store/rtk-query/apis/user/selectors/get-my-user.selector';
import AlertTextEditorHeader from '../text-editor-header/alert-text-editor-header/alert-text-editor-header.component';
import STRING_KEYS from '../../../../../language/keys';
import AutoModeTextEditorHeader from '../text-editor-header/auto-mode-text-edtior-header/auto-mode-text-editor-header.component';
import { MessageResponseDto } from '../../../../../store/rtk-query/apis/outreach/dto/response/message.response';
import { MessageDirectionEnum } from '../../../../../store/rtk-query/apis/outreach/enums/message-direction.enum';
import ManualModeTextEditorHeader from '../text-editor-header/manual-mode-text-edtior-header/manual-mode-text-editor-header.component';
import { IconButtonVariants } from '../../../../../components/Common/Buttons/icon-button/icon-button.enums';
import { PerfectButtonHoverVariants, PerfectButtonSize } from '../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.enums';
import { SOCIAL_TYPES } from '../../../../../consts';
import PerfectTextEditor  from '../../../../../components/Common/perfect-text-editor/perfect-text-editor.component';
import { useConversationMentionsOptions } from '../../../hooks/conversation-mentions-options.hook';
import Tooltip from '../../../../../components/tooltip/tooltip.component';
import {
  MessageHintsContainer,
  Spice,
  SpicesContainer,
  SpicesIcon,
  ToneOfVoiceIcon,
  TooltipTextContainer,
  TooltipTitle,
} from '../../../pages/conversation/conversation.styled';
import useWindowDimensions from '../../../../../hooks/useWindowDimensions';
import TextEditorContext  from '../../../../../components/Common/perfect-text-editor/context/text-editor.context';
import useTextEditorReducer from '../../../../../components/Common/perfect-text-editor/context/text-editor.reducer';
import useClickOutsideListener from '../../../../../hooks/use-click-outside-listener.hooks';
import { getRandomIntBetweenRange } from '../../../../../utils/number';
import { useGetMatchByIdSelector } from '../../../../../store/rtk-query/apis/match/selectors/get-match-by-id.selector';
import { decodeHtml } from '../../../../../utils';
import { useRegenerateConversationSequence } from '../../../../../store/rtk-query/apis/sequence/hooks/regenerate-conversation-sequence.mutation-hook';
import SendViaLinkedinMenu from './send-via-linkedin-menu/send-via-linkedin-menu.component';
import {
  MainContainer,
  EmailContainer,
  FutureLinkedinTaskActionButtonPlaceholder,
  FutureLinkedinTaskActionButtonPlaceholderIcon,
  HeaderWrapper,
  MarkAsDoneButtonIcon,
  MarkAsDoneButton,
  SaveChangesButton,
  SaveChangesButtonIcon,
  SendIcon,
  SendMessageButton,
  ManualModeSendButtonContainer,
  FutureLinkedinTaskActionButtonContainer,
  SendMessageNowButton,
  RegenerateMessageButton,
  RegenerateMessageButtonIcon,
} from './outreach-editor.styled';
import EmailsInput from './emails-input/emails-input.component';
import { OutreachEmail } from './emails-input/emails-input';
import { OutreachEditorProps, SendMessageHandlerArguments } from './outreach-editor';

const SUBJECT_EDITOR_ID = 'outreach_editor_subject';
const BODY_EDITOR_ID = 'outreach_editor_body';

const OutreachEditor = ({
  isAutoMode,
  defaultEmails,
  conversation,
  sendMessageHandler,
  markMessageAsSentHandler,
  disableSendButton,
  alertActionButtonHandler,
  automateFollowupsButtonClickHandler,
  isSendButtonLoading,
  messageSuggestion,
  sendAsReply,
}: OutreachEditorProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const refEditor = useRef<HTMLDivElement>(null);
  const { conversationId } = useParams<MatchUrlParams & { conversationId: string }>();
  const [state, actions] = useTextEditorReducer();
  const {
    touched, editorInFocus, editorBodyState, editorSubjectState, showTextEditorHeader,
  } = state;
  const {
    setTouched, resetEditor, setShowTextEditorHeader, setEditorInFocus,
  } = actions;
  const { width } = useWindowDimensions();
  const [emails, setEmails] = useState<OutreachEmail[]>(defaultEmails || []);
  const [cancelConversationSequence, { isSuccess: isCancelConversationSequenceSuccess }] = useCancelConversationSequenceMutation();
  const [createConversationSequence, { isLoading: isCreateConversationSequenceLoading }] = useCreateConversationSequence();
  const [regenerateConversationSequence, { isLoading: isRegenerateConversationSequenceLoading }] = useRegenerateConversationSequence();
  const [getConversationById] = outreachQuery.useLazyGetConversationByIdQuery();
  const { data: match } = useGetMatchByIdSelector(conversation?.positionId, conversation?.matchId);
  const mentions = useConversationMentionsOptions(conversationId);

  const [updateScheduledMessage, {
    isLoading: isUpdateScheduledMessageLoading,
    isSuccess: isUpdateScheduledMessageSuccess,
  }] = useUpdateScheduledMessageMutation();

  const { data: currentUser } = useMyUserSelector();

  const [updateLinkedinTask, {
    isLoading: isUpdateLinkedinTaskLoading,
  }] = sequenceQuery.useUpdateLinkedinTaskMutation();

  const currentSequenceStep = useMemo(() => {
    return conversation?.nextScheduledMessage?.sequenceStep || conversation?.nextOpenLinkedinTask?.sequenceStep;
  }, [conversation?.nextOpenLinkedinTask?.sequenceStep, conversation?.nextScheduledMessage?.sequenceStep]);

  useEffect(
    () => {
      setShowTextEditorHeader(!!(conversation?.nextScheduledMessage || (isAutoMode && conversation?.nextOpenLinkedinTask)));
    }, [conversation?.nextOpenLinkedinTask, conversation?.nextScheduledMessage, isAutoMode, setShowTextEditorHeader]);

  useEffect(() => {
    if (isCancelConversationSequenceSuccess) {
      if (conversation?.id) {
        getConversationById({ conversationId: conversation.id });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversation?.id, isCancelConversationSequenceSuccess]);

  useEffect(() => {
    if (conversationId) {
      setEmails([]);
    }
  }, [conversationId]);

  useEffect(() => {
    if (defaultEmails) {
      setEmails(defaultEmails);
    }
  }, [defaultEmails]);

  useEffect(() => {
    if (isUpdateScheduledMessageSuccess) {
      setTouched(false);
    }
  }, [isUpdateScheduledMessageSuccess, setTouched]);

  const selectedEmail = useMemo(() => emails.find(({ selected }) => selected)?.value, [emails]);

  const showSubject = useMemo(() => {
    if (sendAsReply) {
      return !sendAsReply;
    }

    return !!conversation?.nextScheduledMessage || !!conversation?.nextOpenLinkedinTask || !conversation?.messageHistory?.length;
  }, [conversation, sendAsReply]);

  const showEmailContainer = useMemo(
    () => {
      return (
        (!isAutoMode && (!conversation?.nextScheduledMessage || !conversation?.messageHistory?.length || conversation?.nextOpenLinkedinTask))
      );
    },
    [conversation?.messageHistory?.length, conversation?.nextOpenLinkedinTask, conversation?.nextScheduledMessage, isAutoMode],
  );

  const showSkeleton = useMemo(
    () => conversation?.sequenceCreationStatus === SequenceCreationStatusEnum.InProgress ||
      conversation?.sequenceCreationStatus === SequenceCreationStatusEnum.Failed ||
      !!match.contactDetails?.lookupInProgress,
    [conversation?.sequenceCreationStatus, match.contactDetails?.lookupInProgress],
  );

  const editorLoadingText = useMemo(() => {
    if (!showSkeleton) {
      return undefined;
    }

    if (match.contactDetails?.lookupInProgress) {
      return translate(STRING_KEYS.OUTREACH_PAGE.EDITOR.CONTACT_DETAILS_LOADING_TEXT);
    }

    const randomNumber = getRandomIntBetweenRange(0, STRING_KEYS.OUTREACH_PAGE.EDITOR.LOADING_TEXT.length - 1);

    return translate(STRING_KEYS.OUTREACH_PAGE.EDITOR.LOADING_TEXT[randomNumber]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSkeleton, match.contactDetails?.lookupInProgress]);

  const sendOrMarkAsSentMessageHandler = (handler: (args: SendMessageHandlerArguments) => void) => {
    const bodyHtml = contentToHtml(editorBodyState.getCurrentContent());
    const bodyPlanText = editorBodyState.getCurrentContent().getPlainText();
    const subjectPlainText = editorSubjectState.getCurrentContent().getPlainText();

    handler({
      bodyHtml,
      bodyPlanText,
      emails: selectedEmail || '',
      subject: decodeHtml(subjectPlainText),
      onSuccess: resetEditor,
    });
  };

  const markMessageAsSent = () => {
    sendOrMarkAsSentMessageHandler(markMessageAsSentHandler);
  };

  const sendMessage = (e?: MouseEvent<HTMLButtonElement>) => {
    e?.stopPropagation();
    sendOrMarkAsSentMessageHandler(sendMessageHandler);
  };

  const onUpdateScheduledMessageButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
    e?.stopPropagation();

    if (!conversation?.nextScheduledMessage?.id) {
      return;
    }

    const subjectPlainText = editorSubjectState.getCurrentContent().getPlainText();
    const bodyHtml = contentToHtml(editorBodyState.getCurrentContent());

    updateScheduledMessage({
      messageId: conversation?.nextScheduledMessage?.id,
      subject: decodeHtml(subjectPlainText),
      bodyHtml,
    });
  };

  const isEditorDataValid = (): boolean => {
    const subjectHasText = editorSubjectState.getCurrentContent()?.hasText();
    const bodyHasText = editorBodyState.getCurrentContent()?.hasText();
    if (showSubject) {
      return bodyHasText && subjectHasText;
    }

    return bodyHasText;
  };

  const isOutreachEditorValid = (validateEmail?: boolean) => {
    if (validateEmail && !selectedEmail) {
      return false;
    }

    return isEditorDataValid();
  };

  const isSendMessageNowButtonActive = useMemo<boolean>((): boolean => {
    const validateEmailAddress = !!conversation?.nextScheduledMessage;

    return isOutreachEditorValid(validateEmailAddress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorBodyState, editorSubjectState, selectedEmail, showSubject, conversation?.nextScheduledMessage]);

  const isSendMessageButtonActive = useMemo(() => {
    if (disableSendButton) {
      return false;
    }

    return isOutreachEditorValid(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disableSendButton, editorBodyState, editorSubjectState, selectedEmail, showSubject]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const editorKeyboardEventHandler = (event: any) => {
    if ((event.metaKey || event.ctrlKey) && event.key === KEYBOARD_KEYS.ENTER) {
      if (isSendMessageButtonActive) {
        sendMessage();

        return null;
      }
    }

    return Draft.getDefaultKeyBinding(event);
  };

  const onMarkLinkedinTaskAsDoneButtonClick = (e?: MouseEvent<HTMLButtonElement>) => {
    e?.stopPropagation();
    const task = conversation?.nextOpenLinkedinTask;

    if (!task) {
      return;
    }

    resetEditor();

    updateLinkedinTask({
      positionId: conversation.positionId,
      conversationId: conversation.id,
      status: LinkedinTaskStatusEnum.Done,
      currentUser,
      task,
    });
  };

  const onSendMessageNowButtonClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (conversation?.nextOpenLinkedinTask) {
      if (!selectedEmail) {
        onMarkLinkedinTaskAsDoneButtonClick();
      } else {
        sendMessage();
      }
    } else if (conversation?.nextScheduledMessage && selectedEmail) {
      sendMessage();
    }
  };

  const onCancelCampaignButtonClick = () => {
    if (conversation?.id) {
      cancelConversationSequence({ conversationId: conversation.id, positionId: conversation.positionId });
    }
  };

  const nextLinkedinTaskSendTimeDifferenceInDays = useMemo(() => {
    const sendAt = conversation?.nextOpenLinkedinTask?.sendAt || conversation?.nextScheduledMessage?.sendAt;

    return sendAt ? differenceInCalendarDays(new Date(sendAt), new Date()) : undefined;
  }, [conversation?.nextOpenLinkedinTask, conversation?.nextScheduledMessage]);

  const isNextLinkedinTaskIsInFuture = useMemo(() => {
    return nextLinkedinTaskSendTimeDifferenceInDays !== undefined && nextLinkedinTaskSendTimeDifferenceInDays > 0;
  }, [nextLinkedinTaskSendTimeDifferenceInDays]);

  const errorAlertBackgroundColor = useMemo(() => isNextLinkedinTaskIsInFuture ? THEME.COLORS.PRIMARY_DARK : THEME.COLORS.ACCENT, [isNextLinkedinTaskIsInFuture]);

  const renderAutoModeHeader = () => {
    if (conversation?.nextOpenLinkedinTask && !selectedEmail) {
      return (
        <AlertTextEditorHeader
          label={ translate(STRING_KEYS.OUTREACH_PAGE.EDITOR.ALERT_HEADER.LABEL) }
          infoText={ translate(STRING_KEYS.OUTREACH_PAGE.EDITOR.ALERT_HEADER.INFO_TEXT) }
          actionButtonHandler={ alertActionButtonHandler }
          backgroundColor={ errorAlertBackgroundColor }
        />
      );
    }

    const completedSteps = currentSequenceStep ? currentSequenceStep - 1 : 0;

    return (
      <AutoModeTextEditorHeader
        totalSteps={ conversation?.sequenceLength || 0 }
        completedSteps={ completedSteps }
        cancelCampaignClickHandler={ onCancelCampaignButtonClick }
        scheduledMessageTime={ conversation?.nextScheduledMessage?.sendAt }
      />
    );
  };

  const latestSentMessage = useMemo(() => conversation?.messageHistory?.slice().reverse()?.find(
    (messageHistory: MessageResponseDto) => messageHistory.messageDirection === MessageDirectionEnum.Outbound,
  ), [conversation?.messageHistory]);

  const renderManualModeHeader = () => {
    return (
      <ManualModeTextEditorHeader
        lastMessageSendTime={ latestSentMessage?.actionTime ? new Date(latestSentMessage?.actionTime) : undefined }
        automateFollowupsButtonClickHandler={ () => automateFollowupsButtonClickHandler?.() }
      />
    );
  };

  const Header = useMemo(() => {
    if (!showTextEditorHeader) {
      return null;
    }

    if (isAutoMode) {
      return renderAutoModeHeader();
    }

    return renderManualModeHeader();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAutoMode, conversation?.nextOpenLinkedinTask, conversation?.nextScheduledMessage, showTextEditorHeader]);

  const editorUnFocusHandler = () => {
    const subjectHasText = editorSubjectState.getCurrentContent().hasText();
    const bodyHasText = editorBodyState.getCurrentContent().hasText();
    if (!subjectHasText && !bodyHasText) {
      setEditorInFocus(false);
    }
  };

  useClickOutsideListener(
    editorUnFocusHandler,
    refEditor.current as HTMLElement,
  );

  const renderSendMessageButton = () => {
    return (
      <SendMessageButton
        variant={ IconButtonVariants.Fill }
        StartIcon={ SendIcon }
        onClick={ sendMessage }
        disabled={ !isSendMessageButtonActive }
        isLoading={ isSendButtonLoading || isUpdateLinkedinTaskLoading }
      />
    );
  };

  const renderMarkLinkedinTaskAsDoneActionButton  = () => {
    return (
      <MarkAsDoneButton
        hoverVariant={ PerfectButtonHoverVariants.Grow }
        size={ PerfectButtonSize.Small }
        onClick={ onMarkLinkedinTaskAsDoneButtonClick }
        StartIcon={ MarkAsDoneButtonIcon }
        isLoading={ isUpdateLinkedinTaskLoading }
      >
        {translate(STRING_KEYS.OUTREACH_PAGE.EDITOR.MARK_AS_DONE_BUTTON_TEXT)}
      </MarkAsDoneButton>
    );
  };

  const renderSaveScheduledMessageButton = () => {
    return (
      <SaveChangesButton
        onClick={ onUpdateScheduledMessageButtonClick }
        StartIcon={ SaveChangesButtonIcon }
        size={ PerfectButtonSize.Medium }
        hoverVariant={ PerfectButtonHoverVariants.Grow }
        disabled={ !touched }
        isLoading={ isUpdateScheduledMessageLoading }
      >
        { translate(STRING_KEYS.OUTREACH_PAGE.EDITOR.SAVE_CHANGES_BUTTON_TEXT) }
      </SaveChangesButton>
    );
  };

  const renderFutureLinkedinTaskActionButtons = (renderActionButton?: boolean) => {
    return (
      <FutureLinkedinTaskActionButtonContainer>
        <FutureLinkedinTaskActionButtonPlaceholder>
          <FutureLinkedinTaskActionButtonPlaceholderIcon />
          <div>
            <Trans
              i18nKey={ STRING_KEYS.OUTREACH_PAGE.EDITOR.FUTURE_TASK_INFO_TEXT }
              values={ { daysCount: nextLinkedinTaskSendTimeDifferenceInDays } }
            />
          </div>
        </FutureLinkedinTaskActionButtonPlaceholder>
        { renderActionButton && (
          <SendMessageNowButton
            onClick={ onSendMessageNowButtonClick }
            StartIcon={ SendIcon }
            size={ PerfectButtonSize.Small }
            hoverVariant={ PerfectButtonHoverVariants.Grow }
            disabled={ disableSendButton || !isSendMessageNowButtonActive }
            isLoading={ (!selectedEmail && isUpdateLinkedinTaskLoading) || isSendButtonLoading }
            backgroundColor={ selectedEmail ? THEME.COLORS.ACCENT : THEME.COLORS.PRIMARY }
          >
            {translate(selectedEmail ? STRING_KEYS.OUTREACH_PAGE.EDITOR.SEND_MESSAGE_NOW_BUTTON_TEXT : STRING_KEYS.OUTREACH_PAGE.EDITOR.MARK_AS_DONE_BUTTON_TEXT)}
          </SendMessageNowButton>
        ) }
      </FutureLinkedinTaskActionButtonContainer>
    );
  };

  const renderAutoModeSendNowButton = () => {
    return (
      <SendMessageNowButton
        onClick={ sendMessage }
        backgroundColor={ THEME.COLORS.TRANSPARENT }
        color={ THEME.COLORS.ACCENT }
        StartIcon={ SendIcon }
        hoverVariant={ PerfectButtonHoverVariants.Grow }
        isLoading={ isSendButtonLoading }
      >
        { translate(STRING_KEYS.OUTREACH_PAGE.EDITOR.SEND_MESSAGE_NOW_BUTTON_TEXT) }
      </SendMessageNowButton>
    );
  };

  const renderAutoModeActionButton = () => {
    if (conversation?.nextScheduledMessage) {
      if (touched) {
        return renderSaveScheduledMessageButton();
      }

      return renderAutoModeSendNowButton();
    }

    if (conversation?.nextOpenLinkedinTask) {
      if (isNextLinkedinTaskIsInFuture) {
        return renderFutureLinkedinTaskActionButtons(true);
      }

      return renderMarkLinkedinTaskAsDoneActionButton();
    }

    return renderSendMessageButton();
  };

  const onSendViaLinkedinMenuButtonClick = async () => {
    if (navigator?.clipboard) {
      await navigator.clipboard.writeText(editorBodyState.getCurrentContent().getPlainText());
    }
  };

  const renderManualModeActionButton = () => {
    if ((conversation?.nextOpenLinkedinTask || conversation?.nextScheduledMessage) && isNextLinkedinTaskIsInFuture) {
      return renderFutureLinkedinTaskActionButtons(true);
    }

    if (conversation?.nextOpenLinkedinTask && !selectedEmail) {
      return renderMarkLinkedinTaskAsDoneActionButton();
    }

    return (
      <ManualModeSendButtonContainer>
        { conversation?.nextScheduledMessage && (
          <SendViaLinkedinMenu
            talentName={ conversation?.match.talent.firstName || '' }
            talentLinkedinUrl={ (conversation?.match?.talent?.socialLinks?.find((socialLink) => socialLink.type === SOCIAL_TYPES.LINKEDIN))?.url || '' }
            menuButtonClickHandler={ onSendViaLinkedinMenuButtonClick }
            markMessageAsSentButtonClickHandler={ markMessageAsSent }
          />
        ) }
        { renderSendMessageButton() }
      </ManualModeSendButtonContainer>
    );
  };

  const getTextEditorSubject = () => {
    return conversation?.nextScheduledMessage?.payload?.subject || conversation?.nextOpenLinkedinTask?.payload?.subject || messageSuggestion?.subject;
  };

  const getTextEditorMessage = () => {
    return conversation?.nextScheduledMessage?.payload?.html || conversation?.nextOpenLinkedinTask?.payload?.html || messageSuggestion?.body;
  };

  const renderTextEditorInfoComponent = () => {
    const nextMessage = conversation?.nextOpenLinkedinTask || conversation?.nextScheduledMessage;

    if (!nextMessage?.payload?.spicesUsed && !nextMessage?.payload?.toneOfVoice) {
      return undefined;
    }

    const spices = Object
      .keys(nextMessage.payload.spicesUsed || {})
      .filter(Boolean)
      .slice(0, 2)
      .map((spice) => (
        <Tooltip
          key={ spice }
          content={ (
            <TooltipTextContainer>
              <TooltipTitle>{ translate(STRING_KEYS.OUTREACH_PAGE.EDITOR.SPICES_INFO.SPICE.TOOLTIP_TITLE) }</TooltipTitle>
              <div>{ translate(STRING_KEYS.OUTREACH_PAGE.EDITOR.SPICES_INFO.SPICE.TOOLTIP_DESCRIPTION) }</div>
            </TooltipTextContainer>
          ) }
        >
          <Spice>
            <SpicesIcon />
            { translate(STRING_KEYS.OUTREACH_PAGE.SPICES[spice]) }
          </Spice>
        </Tooltip>
      ));

    return (
      <MessageHintsContainer>
        { width > THEME.BREAKPOINTS.WIDTH.MEDIUM && (<div>{ translate(STRING_KEYS.OUTREACH_PAGE.EDITOR.SPICES_INFO.HINT_TEXT) }</div>) }
        <SpicesContainer>
          <Tooltip
            content={ (
              <TooltipTextContainer>
                <TooltipTitle>{ translate(STRING_KEYS.OUTREACH_PAGE.EDITOR.SPICES_INFO.TONE_OF_VOICE.TOOLTIP_TITLE) }</TooltipTitle>
                <div>{ translate(STRING_KEYS.OUTREACH_PAGE.EDITOR.SPICES_INFO.TONE_OF_VOICE.TOOLTIP_DESCRIPTION) }</div>
              </TooltipTextContainer>
            ) }
          >
            <Spice>
              <ToneOfVoiceIcon />
              { translate(STRING_KEYS.OUTREACH_PAGE.TONE_OF_VOICE[nextMessage?.payload.toneOfVoice || '']) }
            </Spice>
          </Tooltip>
          { spices }
        </SpicesContainer>
      </MessageHintsContainer>
    );
  };

  const ActionButton = isAutoMode ? renderAutoModeActionButton() : renderManualModeActionButton();

  const onRegenerateMessageButtonClick = (event: MouseEvent): void => {
    event.stopPropagation();
    regenerateConversationSequence({ conversationId });
  };

  const getActionControlsAdornment = (): JSX.Element | undefined => {
    if (!conversation?.nextOpenLinkedinTask && !conversation?.nextScheduledMessage) {
      return undefined;
    }

    return (
      <RegenerateMessageButton
        hoverVariant={ PerfectButtonHoverVariants.Grow }
        size={ PerfectButtonSize.Small }
        StartIcon={ RegenerateMessageButtonIcon }
        onClick={ onRegenerateMessageButtonClick }
        isLoading={ isRegenerateConversationSequenceLoading }
      >
        { translate(STRING_KEYS.OUTREACH_PAGE.EDITOR.REGENERATE_MESSAGE_BUTTON_TEXT) }
      </RegenerateMessageButton>
    );
  };

  return (
    <TextEditorContext.Provider
      value={ {
        state,
        actions,
      } }
    >
      <MainContainer ref={ refEditor }>
        { showEmailContainer && (
          <EmailContainer isVisible={ editorInFocus }>
            <EmailsInput
              emails={ emails }
              setEmailsHandler={ setEmails }
              alertActionButtonHandler={ () => alertActionButtonHandler?.() }
              errorAlertBackgroundColor={ isNextLinkedinTaskIsInFuture ? THEME.COLORS.PRIMARY_DARK : undefined }
            />
          </EmailContainer>
        ) }
        <div>
          { Header && <HeaderWrapper>{ Header }</HeaderWrapper> }
          <PerfectTextEditor
            subjectContainerId={ SUBJECT_EDITOR_ID }
            bodyContainerId={ BODY_EDITOR_ID }
            showSubject={ showSubject }
            subject={ getTextEditorSubject() }
            message={ getTextEditorMessage() }
            resetKey={ conversationId }
            mentions={ mentions }
            InfoComponent={ renderTextEditorInfoComponent() }
            ActionButton={ ActionButton }
            editorKeyboardEventHandler={ editorKeyboardEventHandler }
            errorChipButtonClickHandler={
              () => conversation?.id && createConversationSequence({ conversationId: conversation?.id })
            }
            skeleton={ {
              visible: showSkeleton,
              showAnimation: isCreateConversationSequenceLoading ||
                conversation?.sequenceCreationStatus === SequenceCreationStatusEnum.InProgress,
              hasError: conversation?.sequenceCreationStatus === SequenceCreationStatusEnum.Failed,
              isErrorActionLoading: isCreateConversationSequenceLoading,
              loadingText: editorLoadingText,
            } }
            ActionControlsAdornment={ getActionControlsAdornment() }
          />
        </div>
      </MainContainer>
    </TextEditorContext.Provider>
  );
};

export default OutreachEditor;
