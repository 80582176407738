import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import StyledAvatar from '../../../../../components/Common/styled-avatar/styled-avatar.component';
import STRING_KEYS from '../../../../../language/keys';
import { createFullName } from '../../../../../utils';
import {
  PerfectButtonHoverVariants,
  PerfectButtonSize,
  PerfectButtonVariants,
} from '../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.enums';
import { SocialLinksType } from '../../outreach/match-side-panel/social-links/social-links.enums';
import { MatchSocialLink } from '../../../../../@types/api';
import { AnonymizeTalentTooltipComponent } from '../../anonymize-tooltip/anonymize-talent-tooltip.component';
import { HeaderProps } from './header';
import {
  AvatarContainer,
  ButtonsContainer,
  Container,
  EngageButton,
  EngageIcon,
  LinkedinButton,
  LinkedinIcon,
  SkipButton,
  TalentDescription,
  TalentInfo,
  TalentInfoContainer,
  TalentName,
  TalentNameContainer,
  QuestionIcon,
} from './header.styled';

const Header = ({
  match,
  engageHandler,
  declineHandler,
  disableActions,
  hideActions,
  anonymizeTalentDetails,
}: HeaderProps):JSX.Element => {
  const { t: translate } = useTranslation();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const talentFullName = useMemo(() => createFullName(match?.talent?.firstName, match?.talent?.lastName), [match?.id]);
  const talentLinkedinUrl = useMemo(() => {
    return match?.talent?.socialLinks?.find((social: MatchSocialLink) => social.type === SocialLinksType.LinkedIn);
  }, [match?.talent?.socialLinks]);

  return (
    <Container>
      <TalentInfo>
        <AnonymizeTalentTooltipComponent anonymizeTalentDetails={ anonymizeTalentDetails }>
          <AvatarContainer>
            <StyledAvatar
              image={ match.talent?.profileImage }
              name={ talentFullName }
              initialsFontSizePx={ 38 }
              outlineWidthPx={ 0 }
              borderWidthPx={ 0 }
            />
          </AvatarContainer>
        </AnonymizeTalentTooltipComponent>
        <TalentInfoContainer>
          <TalentNameContainer>
            <AnonymizeTalentTooltipComponent anonymizeTalentDetails={ anonymizeTalentDetails }>
              <TalentName>{ talentFullName }</TalentName>
            </AnonymizeTalentTooltipComponent>
            { talentLinkedinUrl?.url && (
              <LinkedinButton
                href={ talentLinkedinUrl.url }
                target="_blank"
                rel="noreferrer"
              >
                <LinkedinIcon />
              </LinkedinButton>
            ) }
            { anonymizeTalentDetails && (
              <AnonymizeTalentTooltipComponent anonymizeTalentDetails={ anonymizeTalentDetails }>
                <QuestionIcon />
              </AnonymizeTalentTooltipComponent>
            ) }

          </TalentNameContainer>
          <TalentDescription>
            { match.summary?.text }
          </TalentDescription>
        </TalentInfoContainer>
      </TalentInfo>
      {
        !hideActions && (
          <ButtonsContainer>
            <SkipButton
              type="button"
              variant={ PerfectButtonVariants.Fill }
              hoverVariant={ PerfectButtonHoverVariants.Grow }
              size={ PerfectButtonSize.ExtraLarge }
              onClick={ declineHandler }
              disabled={ disableActions }
            >
              { translate(STRING_KEYS.SKIP) }
            </SkipButton>
            <EngageButton
              type="button"
              variant={ PerfectButtonVariants.Fill }
              hoverVariant={ PerfectButtonHoverVariants.Grow }
              StartIcon={ EngageIcon }
              size={ PerfectButtonSize.ExtraLarge }
              onClick={ engageHandler }
              disabled={ disableActions }
            >
              { translate(STRING_KEYS.MATCH_CARD.ENGAGE_BUTTON_TEXT) }
            </EngageButton>
          </ButtonsContainer>
        )
      }
    </Container>
  );
};

export default Header;
