import TextInputItem from '../text-input-item/text-input-item.component';
import { TextInputItemDirection } from '../text-input-item/text-input-item.enums';
import PerfectCheckbox from '../../../../../components/Common/perfect-checkbox/perfect-checkbox.component';
import { TextInputWithCheckboxItemProps } from './text-input-with-checkbox-item';
import {
  CheckboxContainer,
  CheckboxDescription,
  CheckboxTitle,
} from './text-input-with-checkbox-item.styled';

const TextInputWithCheckboxItem = ({
  inputTitle,
  inputValue,
  inputDescription,
  inputPlaceholder,
  inputChangeHandler,
  checkboxTitle,
  checkboxValue,
  checkboxDescription,
  checkboxChangeHandler,
}: TextInputWithCheckboxItemProps): JSX.Element => {
  return (
    <TextInputItem
      value={ inputValue }
      changeHandler={ inputChangeHandler }
      title={ inputTitle }
      description={ inputDescription }
      placeholder={ inputPlaceholder }
      direction={ TextInputItemDirection.COLUMN }
    >
      <CheckboxContainer>
        <PerfectCheckbox
          checked={ checkboxValue }
          clickHandler={ () => checkboxChangeHandler(!checkboxValue) }
        />
        <div>
          <CheckboxTitle>
            { checkboxTitle }
          </CheckboxTitle>
          <CheckboxDescription>
            { checkboxDescription }
          </CheckboxDescription>
        </div>
      </CheckboxContainer>
    </TextInputItem>
  );
};

export default TextInputWithCheckboxItem;
