import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import { clone } from 'lodash';
import STRING_KEYS from '../../../../../language/keys';
import RequirementChip from '../../../../../components/Common/requirement-chip/requirement-chip.component';
import { Input } from '../../../../onboarding/components/input/input.component';
import OpenGuidanceModalButton
  from '../../../../../components/dialogs/guidance-modal/open-guidance-modal-button/open-guidance-modal-button.component';
import DoDontGuidanceModal
  from '../../../../../components/dialogs/guidance-modal/do-dont-guidance-modal/do-dont-guidance-modal.component';
import {
  Container,
  DividerContainer,
  EmptyStateMessage,
  GuidanceModalButtonWrapper,
  InputContainer,
  PlusIcon,
  StyledDivider,
  SuggestionListContainer,
} from './edit-job-requirement.styled';
import { EditJobRequirementProps } from './edit-job-requirement';

export const DEFAULT_JOB_REQUIREMENT_ID = 'DEFAULT_JOB_REQUIREMENT';

export const EditJobRequirement = ({
  enableRequirement,
  selectedJobRequirements,
  setSelectedJobRequirementsHandler,
  emptyStateText,
  guidanceModalData,
}: EditJobRequirementProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const [input, setInput] = useState('');
  const [isInputFocused, setInputFocused] = useState<boolean>(false);
  const [filteredJobRequirements, setFilteredJobRequirements] = useState<FormattedJobRequirementItem[]>([] as FormattedJobRequirementItem[]);
  const [isGuidanceModalOpen, setIsGuidanceModalOpen] = useState<boolean>(false);

  // TODO: create hook and reuse with create job requirement in create position
  useEffect(() => {
    if (selectedJobRequirements?.length && filteredJobRequirements?.length) {
      const setCustomIndustriesForFilter = new Set(selectedJobRequirements.map(({ title }) => title));
      setFilteredJobRequirements((prevState) => {
        return prevState.reduce((acc, jobRequirement) => {
          if (!setCustomIndustriesForFilter.has(jobRequirement.jobRequirementTitle)) {
            acc.push(jobRequirement);
          }

          return acc;
        }, [] as FormattedJobRequirementItem[]);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedJobRequirements, selectedJobRequirements?.length]);

  const onClickEnter = useCallback(() => {
    if (!input || selectedJobRequirements?.some((jobRequirement) => jobRequirement.title === input)) {
      return;
    }

    const newItem : PositionJobRequirementState = {
      selected: true,
      required: false,
      title: input,
    };

    setSelectedJobRequirementsHandler?.([...(selectedJobRequirements || []), newItem]);
    setInput('');
    setInputFocused(true);
  }, [input, selectedJobRequirements, setSelectedJobRequirementsHandler]);

  const onChipRemoveButtonClick = (selectedJobRequirement: PositionJobRequirementState) => {
    if (selectedJobRequirement.id !== DEFAULT_JOB_REQUIREMENT_ID && selectedJobRequirement.selected) {
      setSelectedJobRequirementsHandler(selectedJobRequirements.filter((jobRequirement) => jobRequirement.title !== selectedJobRequirement.title));

      return;
    }
    setSelectedJobRequirementsHandler(selectedJobRequirements.slice().map((jobRequirement) => {
      if (jobRequirement.title === selectedJobRequirement.title) {
        jobRequirement.selected = !jobRequirement.selected;
      }

      return jobRequirement;
    }));
  };

  const onChipClick = (selectedJobRequirement: PositionJobRequirementState) => {
    setSelectedJobRequirementsHandler(
      clone(selectedJobRequirements).map((jobRequirement) => {
        if (jobRequirement.title === selectedJobRequirement.title) {
          jobRequirement.required = !jobRequirement.required;
        }

        return jobRequirement;
      }),
    );
  };

  const renderSuggestionList = () => {
    if (!selectedJobRequirements?.length) {
      return <EmptyStateMessage>{ emptyStateText }</EmptyStateMessage>;
    }

    return (
      <SuggestionListContainer>
        { selectedJobRequirements?.map((selectedJobRequirement) => (
          <RequirementChip
            key={ selectedJobRequirement.title }
            title={ selectedJobRequirement.title }
            required={ selectedJobRequirement.required }
            removeButtonClickHandler={ () => onChipRemoveButtonClick(selectedJobRequirement) }
            clickHandler={ enableRequirement ? () => onChipClick(selectedJobRequirement) : undefined }
          />
        ))}
      </SuggestionListContainer>
    );
  };

  return (
    <>
      <Container>
        { guidanceModalData && (
          <GuidanceModalButtonWrapper>
            <OpenGuidanceModalButton clickHandler={ () => setIsGuidanceModalOpen(true) } />
          </GuidanceModalButtonWrapper>
        ) }
        { renderSuggestionList() }
        <DividerContainer>
          <StyledDivider />
          <PlusIcon />
          <StyledDivider />
        </DividerContainer>
        <InputContainer>
          <Input
            autoFocus={ false }
            placeholder={ translate(STRING_KEYS.MATCH_TUNE.REQUIREMENT_CONTAINER.INPUT_PLACEHOLDER) }
            value={ input }
            onValueChange={ setInput }
            onEnter={ onClickEnter }
            isInputFocused={ isInputFocused }
          />
        </InputContainer>
      </Container>
      { guidanceModalData && isGuidanceModalOpen && (
        <DoDontGuidanceModal
          isOpen={ isGuidanceModalOpen }
          closeHandler={ () => setIsGuidanceModalOpen(false) }
          title={ guidanceModalData.title }
          data={ guidanceModalData.data }
        />
      ) }
    </>
  );
};
