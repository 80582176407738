import styled from 'styled-components';
import { THEME } from '../../../../../themes';
import { CaptionFont, Subtitle1Font } from '../../../../../themes/fonts';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${THEME.SPACING.TINO};
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 260px;
  gap: ${THEME.SPACING.TINY};
`;

export const Description = styled.div`
  ${CaptionFont};
  color: ${THEME.COLORS.CONTENT_A};
  opacity: ${THEME.OPACITY.MED};
`;

export const SectionItemTitle = styled.div`
  ${Subtitle1Font};
  font-weight: 600;
`;
