import { useState } from 'react';
import { ApiError } from '../../../../../consts/api';
import { positionQuery } from '../position.toolkit-api';
import { PositionResponse } from '../dto/response/position.response';
import { matchQuery } from '../../match/match.toolkit-api';
import { PositionRunTriggerTypeEnum } from '../../../../../enums/position-run-trigger-type.enum';

type UseByeBiasMutationType = [
  (positionId: string, anonymizeTalentDetails: boolean) => void,
  AppSelectorType<PositionResponse, ApiError>
];

export const useUpdatePositionByeBiasMutation = (): UseByeBiasMutationType => {
  const [
    updatePosition,
    {
      data, isLoading, isError, error, isSuccess, reset,
    }] = positionQuery.useUpdatePositionMutation();

  const [isPositionByeBiasLoading, setIsPositionByeBiasLoading] = useState(false);

  const [
    getPendingReviewMatches,
  ] = matchQuery.useLazyGetPendingReviewMatchesQuery();

  const updatePositionMeta = {
    isLoading, isError, error: error as ApiError, isSuccess, data, resetState: reset,
  };

  const updateByeBias = async (positionId: string, anonymizeTalentDetails: boolean) => {
    try {
      setIsPositionByeBiasLoading(true);
      await updatePosition({ positionId, anonymizeTalentDetails, triggerType: PositionRunTriggerTypeEnum.MatchTune }).unwrap();
      await getPendingReviewMatches({ positionId });
    } catch (e) {
      // Will be handled by isError
    }
    setIsPositionByeBiasLoading(false);
  };

  return [
    updateByeBias,
    { ...updatePositionMeta, isLoading: isPositionByeBiasLoading },
  ];
};
