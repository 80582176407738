import {
  FormikErrors, FormikTouched, useFormik,
} from 'formik';
import * as Yup from 'yup';
import { OutreachSequencePreferencesFormikKeys } from '../../../pages/campaign/campaign.enums';
import { CustomStep } from '../../../../../store/rtk-query/apis/sequence/dto/sequence-preferences.dto';
import {
  SequenceMessageTypeEnum,
} from '../../../../../store/rtk-query/apis/sequence/enums/sequence-message-type.enum';
import {
  SequenceScheduleDaysModeEnum,
} from '../../../../../store/rtk-query/apis/sequence/enums/sequence-schedule-mode.enum';
import {
  UpdateCustomStepsQueryArguments,
} from '../../../../../store/rtk-query/apis/sequence/dto/query-arguments/update-custom-steps.query-arguments';
import { Step } from './campaign-custom-mode';
import { EMPTY_STEP, FIRST_TIME_DAYS } from './campaign-custom-mode-dialog/campaign-custom-mode-dialog.consts';

const STEP_FORMIK_KEY = 'steps';

export type CustomModeFormikValues = {
  [STEP_FORMIK_KEY]: Step[],
};

export type CampaignCustomModeFormikFormik = CustomModeFormikValues & {
  setValueByKey: (value: Step[]) => void,
  save: () => Promise<void>;
  dirty: boolean,
  isValid: boolean,
  resetForm: () => void,
  errors: FormikErrors<CustomModeFormikValues>,
  touched: FormikTouched<CustomModeFormikValues>,
};

const validationSchema = Yup.object({
  [OutreachSequencePreferencesFormikKeys.CustomSteps]: Yup
    .array()
    .of(
      Yup.object().shape({
        type: Yup.string().required(),
        days: Yup.string().required(),
        isWorkDays: Yup.boolean().required(),
        time: Yup.string().required(),
        isManual: Yup.boolean(),
        sendAsReply: Yup.boolean(),
        subject: Yup.string().when('sendAsReply', {
          is: false,
          then: (schema) => schema.required(),
        }),
        message: Yup.string().required(),
      }),
    ),
});

const getInitialSteps = (customSteps?: CustomStep[]): Step[] => customSteps?.map((step) => {
  const {
    messageType, time, daysFromLastEmail, scheduleDaysMode, subject, body, sendAsReply,
  } = step;

  return {
    type: messageType || SequenceMessageTypeEnum.Email,
    days: `${daysFromLastEmail}`,
    isWorkDays: scheduleDaysMode === SequenceScheduleDaysModeEnum.BusinessDays,
    time,
    isManual: messageType && messageType !== SequenceMessageTypeEnum.Email,
    initMessage: body,
    initSubject: subject,
    message: body,
    subject,
    sendAsReply,
  } as Step;
}) || [
  {
    ...EMPTY_STEP,
    days: FIRST_TIME_DAYS,
    sendAsReply: false,
  },
  { ...EMPTY_STEP },
  { ...EMPTY_STEP },
] as Step[];

export const useCampaignCustomModeFormik = (
  submitHandler: (updateCustomSteps: UpdateCustomStepsQueryArguments) => void,
  positionId: string,
  customSteps?: CustomStep[],
): CampaignCustomModeFormikFormik => {
  const formik = useFormik<CustomModeFormikValues>({
    initialValues: { [STEP_FORMIK_KEY]: getInitialSteps(customSteps) },
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const payload = values[STEP_FORMIK_KEY].map<CustomStep>((step) => {
        const {
          type, days, subject, sendAsReply, time, message, isWorkDays,
        } = step;

        return {
          messageType: type || SequenceMessageTypeEnum.Email,
          subject: !sendAsReply ? subject : undefined,
          body: message || '',
          sendAsReply: sendAsReply || false,
          daysFromLastEmail: Number(days) || 0,
          scheduleDaysMode: isWorkDays ? SequenceScheduleDaysModeEnum.BusinessDays : SequenceScheduleDaysModeEnum.AnyDay,
          time: time || '',
        };
      });

      return submitHandler({
        positionId,
        steps: payload,
      });
    },
  });

  return {
    setValueByKey: (steps) => {
      formik.setFieldTouched(STEP_FORMIK_KEY);
      formik.setFieldValue(STEP_FORMIK_KEY, steps);
    },
    steps: formik.values.steps,
    save: formik.submitForm,
    dirty: formik.dirty,
    errors: formik.errors,
    isValid: formik.isValid,
    resetForm: formik.resetForm,
    touched: formik.touched,
  };
};
